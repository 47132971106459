import {useState, useEffect} from 'react';
import {Col, Row, Form, Input, Upload, Button, message, Switch} from 'antd';
import {useDispatch} from 'react-redux';
import {createSlide, updateSlide} from '../../../redux/slices/frontpage';
import {normFile, beforeUpload, dummyRequest} from '../../../utils/images';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';

const Editor = ({banner,  closeEditor, handleBanner}) => {
  const [sending, handleSending] = useState(false);
  const [baseImg, handleBaseImg] = useState('');
  const [baseImg2, handleBaseImg2] = useState('');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (banner) {
        const newValues = {
          promo: banner.inside_promo,
          url: banner.url,
          position: banner.position,
          name: banner.name,
        };
        handleBaseImg(banner.image || '');
        handleBaseImg2(banner.image2 || '');
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [banner]);

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      if (baseImg && baseImg.length && baseImg2 && baseImg2.length) {
        const info = new FormData();
        if (banner) {
          info.append('id', banner.id);
        }
        info.append('url', values.url);
        // info.append('text', 'Activar');
        // info.append('position', 3);
        info.append('name', values.name);
        info.append('text', values.name);
        info.append('inside_promo', values.promo);
        info.append('position', values.position);
        info.append('image', values.image?.length ? values.image[0]?.originFileObj : baseImg);
        info.append('image2', values.image2?.length ? values.image2[0]?.originFileObj : baseImg2);
        
        if (banner) {
          const response = await dispatch(updateSlide(banner.id, info));
          if (response.status === 'success') {
            message.success('Cambios realizados con éxito');
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        } else {
          const response = await dispatch(createSlide(info));
          if (response.status === 'success') {
            message.success('Cambios realizados con éxito');
            handleBanner(response.banner);
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        }
      } else {
        message.error('Seleciona una imagen válida');
      }

      handleSending(false);
    } catch (e) {
      console.log(e);
    }
  };
  

  return (
    <div>
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <span>{banner ? 'Editar banner' : 'Agregar banner'}</span>
            <Close action={closeEditor} />
          </div>
        </Col>
      </Row>
      <Form
        form={form}
        name="product-editor"
        layout="vertical"
        autoComplete="off"
        colon={false}
        requiredMark={false}
        className={styles.form}
        onFinish={save}
        initialValues={{promo: false, url: ''}}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <div className={styles.swtichWrap}>
                  <span className={styles.label}>
                    Banner promoción
                  </span>
                  <Form.Item
                    name="promo"
                    className={styles.noMargin}
                    valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={10}>
                <div className={styles.preview} style={{backgroundImage: `url(${baseImg})`}} />
              </Col>
              <Col span={14}>
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  label={
                    <span className={styles.label}>
                      IMAGEN DE BANNER WEB
                    </span>
                  }
                  extra={
                    <span className={styles.center}>
                      (Formatos jpg/png de 720x440px)
                    </span>
                  }>
                  <Upload
                    showUploadList={false}
                    customRequest={e => dummyRequest(e, handleBaseImg)}
                    beforeUpload={beforeUpload}
                    className={styles.upload}>
                    <Button size="large" className={styles.uploadImage} block>
                      Seleccionar imagen
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={10}>
                <div className={styles.preview} style={{backgroundImage: `url(${baseImg2})`}} />
              </Col>
              <Col span={14}>
                <Form.Item
                  name="image2"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  label={
                    <span className={styles.label}>
                      IMAGEN DE BANNER MÓVIL
                    </span>
                  }
                  extra={
                    <span className={styles.center}>
                      (Formatos jpg /png de 480x750px)
                    </span>
                  }>
                  <Upload
                    showUploadList={false}
                    customRequest={e => dummyRequest(e, handleBaseImg2)}
                    beforeUpload={beforeUpload}
                    className={styles.upload}>
                    <Button size="large" className={styles.uploadImage} block>
                      Seleccionar imagen
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={
                <span className={styles.label}>
                  NOMBRE
                </span>
              }
              name="name"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar nombre'}]}
            >
              <Input
                size="large"
                className={styles.input}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className={styles.label}>
                  POSICIÓN
                </span>
              }
              name="position"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar posición'}]}
            >
              <Input
                size="large"
                className={styles.input}
                type="number"
              />
            </Form.Item>
            <Form.Item
              label={
                <span className={styles.label}>
                  ENLACE
                </span>
              }
              name="url"
              className={styles.itemColumn}
            >
              <Input
                size="large"
                className={styles.input}
                placeholder="Ejemplo: https://www.marcasexclusivas.com/enlace"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.submitWrap}>
              <Form.Item className={styles.itemSubmit}>
                <Button loading={sending} className={styles.submit} type="primary" size="large" htmlType="submit">
                  GUARDAR BANNER
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Editor;
