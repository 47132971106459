import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import {
  getAllBoletines,
  getExportBoletines,
  getFilterBoletines,
  activeBoletin,
  selectBoletines,
  selectLoaded,
  selectPage,
  setPage,
  setLoaded
} from "../../redux/slices/boletines";
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import BoletinUI from './components/BoletinUI';

const Boletines = () => {
    const today = moment.tz('America/El_Salvador');
    const loaded = useSelector(selectLoaded);
    const [loading, handleLoading] = useState(true);
    const boletines = useSelector(selectBoletines);
    const page = useSelector(selectPage);
    const dispatch = useDispatch();
    const [endDate, handleEndDate] = useState(today);
    const [beginDate, handleBeginDate] = useState(
        moment.tz('America/El_Salvador').subtract(7, 'days')
    );

    const initialFetch = useCallback(async () => {
        handleLoading(true);
        const fecha = {
            date_begin: beginDate.format('DD/MM/YYYY 00:00:00'),
            date_end: endDate.format('DD/MM/YYYY 23:59:59')
        };
        const [response] = await Promise.all([
            dispatch(getFilterBoletines(fecha))
        ]);
        //const [response] =
        //await Promise.all([
        //    dispatch(getAllBoletines())
        //]);
        if (response.status !== "success") {
            message.error("¡Hubo un problema!");
        }
        handleLoading(false);
        dispatch(setLoaded(true));
    }, [beginDate, endDate]);

    useEffect(() => {
        initialFetch();
    }, [initialFetch, loaded, beginDate, endDate]);

    const handleFilter = async () => {
        const begin = beginDate || moment().tz('America/El_Salvador').startOf('day');
        const end = endDate || moment().tz('America/El_Salvador').endOf('day');
        const fecha = {
            date_begin: begin.format('DD/MM/YYYY'),
            date_end: end.format('DD/MM/YYYY')
        };
        const [response] = await Promise.all([
            dispatch(getFilterBoletines(fecha))
        ]);
        if (response.status !== "success") {
            message.error("¡Hubo un problema!");
        }
        handleLoading(false);
    }

    const handleDates = (newDates) => {
        handleBeginDate(newDates[0]);
        handleEndDate(newDates[1]);
      };

    const handleExport = async (fecha) => {
        handleLoading(true);
        const response = await getExportBoletines(fecha);
        console.log(response);
        if (response.status === 200) {
            // const blob_file = await response.blob(); // Convertir la respuesta en Blob
            const type = response.headers['content-type']
            const blob_file = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            const url_file = window.URL.createObjectURL(blob_file); // Crear URL temporal
            const link_file = document.createElement("a"); // Crear un enlace para descargar

            const dateBeginFormatted = fecha.date_begin.split(" ")[0].replace(/\//g, "-");
            const dateEndFormatted = fecha.date_end.split(" ")[0].replace(/\//g, "-");
            
            link_file.href = url_file;
            link_file.download = `marex_boletin_${dateBeginFormatted}_al_${dateEndFormatted}.xlsx`;
            document.body.appendChild(link_file);
            link_file.click(); // Simular clic para descargar
            link_file.remove(); // Eliminar el enlace después de descargar
        } else {
            message.error('¡Hubo un problema al generar el documento!');
        }
        handleLoading(false);
        return;
    }

    const handleActive = async (idBoletin, index, val) => {
        const response = await dispatch(activeBoletin(idBoletin, index, val));
        if (response.status !== 'success') {
          message.error('¡Hubo un problema!');
        }
      };

    const handlePage = newPage => {
        dispatch(setPage(newPage));
    };
    
    return (
        <BoletinUI
            loading={loading}
            boletines={boletines}
            exportData={handleExport}
            handleFilter={handleFilter}
            handleActive={handleActive}
            page={page}
            handlePage={handlePage}
            handleDates={handleDates}
            beginDate={beginDate}
            endDate={endDate}
        />
    );
}

export default Boletines;
