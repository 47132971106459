import { configureStore } from "@reduxjs/toolkit";
import blogs from "./slices/blogs";
import branches from "./slices/branches";
import categories from "./slices/categories";
import clients from "./slices/clients";
import frontpage from "./slices/frontpage";
import history from "./slices/history";
import monitor from "./slices/monitor";
import pages from "./slices/pages";
import products from "./slices/products";
import promos from "./slices/promos";
import toppings from "./slices/toppings";
import user from "./slices/user";
import users from "./slices/users";
import vehicles from "./slices/vehicles";
import zones from "./slices/zones";
import boletines from "./slices/boletines";

export const store = configureStore({
  reducer: {
    blogs,
    branches,
    categories,
    clients,
    frontpage,
    history,
    monitor,
    pages,
    products,
    promos,
    toppings,
    user,
    users,
    vehicles,
    zones,
    boletines
  },
});
