import { Layout, Menu, Dropdown } from "antd";
import moment from "moment-timezone";
import "moment/locale/es";
import { Link, useLocation, Routes, Route } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo/LogoMarcasExclusivas.svg";
import { ReactComponent as Ordenes } from "../../../assets/navigation/ordenes.svg";
import { ReactComponent as Portada } from "../../../assets/navigation/portada.svg";
import { ReactComponent as Productos } from "../../../assets/navigation/producto.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/navigation/categories.svg";
import { ReactComponent as Ajustes } from "../../../assets/navigation/ajustes.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as Account } from "../../../assets/icons/account.svg";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as Powered } from "../../../assets/icons/powered.svg";
import Monitor from "../../monitor/Main";
import Banners from "../../banners/Main";
import History from "../../history/Main";
import Products from "../../products/Main";
import Settings from "../../settings/Main";
import Vehicles from "../../vehicles/Main";
import Zones from "../../zones/Main";
import Users from "../../users/Main";
import Clients from "../../clients/Main";
import Client from "../../client-detail/Main";
import Categories from "../../categories/Main";
import Statistic from "../../statistics/Main";
import Boletines from "../../boletin/Main";
import styles from "../styles/DashboardUI.module.css";

const { Header, Content, Sider } = Layout;

const DashboardUI = ({ closeSession, user, isAdmin }) => {
  const location = useLocation();

  const NavItem = ({ to, label, Icon }) => {
    const path = location.pathname.split("/")[1];
    const base = `/${path}`;
    const isActive = base === to || (path === "historial" && to === "/");

    return (
      <Link
        to={to}
        className={`${styles.item} ${isActive ? styles.active : ""}`}
      >
        <Icon
          className={`${styles.icon} ${isActive ? styles.activeIcon : ""}`} 
        />
        <span>{label}</span>
      </Link>
    );
  };


  const menu = (
    <Menu>
      <Menu.Item onClick={closeSession} danger>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className={styles.layout}>
      {isAdmin && (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={98}
          className={styles.sider}
        >
          <div className={styles.logoWrap}>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.nav}>
            {/*<NavItem to="/" label="Pedidos" Icon={Ordenes} />*/}
            <NavItem to="/" label="Portada" Icon={Portada} />
            <NavItem to="/productos" label="Productos" Icon={Productos} />
            <NavItem to="/categorias" label="Categorias" Icon={CategoryIcon} />
            {/* <NavItem to="/kpi" label="KPIs" Icon={Estadisticas} /> */}
            <NavItem to="/ajustes" label="Ajustes" Icon={Ajustes} />
          </div>
        </Sider>
      )}
      <Layout className={styles.layout}>
        <Header className={styles.header}>
          <div className={styles.headerInfo}>
            <span className={styles.title}>Panel Administrativo</span>
            <Logo className={styles.logoMobile} />
            <div className={styles.wrapDate}>
              <Clock />
              <span className={styles.date}>
                {moment()
                  .tz("America/El_Salvador")
                  .format("D [de] MMMM  -  hh:mm A")}
              </span>
            </div>
            <Dropdown overlay={menu}>
              <div className={styles.accountWrap}>
                <span className={styles.account}>
                  {user.employee_info.name}
                </span>
                <ChevronDown className={styles.chevron} />
                <div className={styles.iconAccount}>
                  <Account />
                </div>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content className={styles.content}>
          <Routes>
            <Route path="/">
              <Route path="/" element={<Banners />} />
              <Route path="historial" element={<History />} />
            </Route>
            <Route path="productos" element={<Products />} />
            <Route path="categorias" element={<Categories />} />
            <Route path="kpi" element={<Statistic/>} />
            <Route path="ajustes">
              <Route path="" element={<Settings />} />
              <Route path="zonas" element={<Zones />} />
              <Route path="motos" element={<Vehicles />} />
              <Route path="usuarios" element={<Users />} />
              <Route path="boletines" element={<Boletines />} />
              {/*<Route path="parametros" element={<Parametros />} /> */}
              <Route path="clientes">
                <Route path="" element={<Clients />} />
                <Route path=":id" element={<Client />} />
              </Route>
            </Route>
          </Routes>
        </Content>
        <div className={styles.powered}>
          <a
            href="https://www.expresateweb.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Powered />
          </a>
        </div>
      </Layout>
    </Layout>
  );
};

export default DashboardUI;
