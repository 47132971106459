import { useState, useEffect, useRef } from 'react';
import {Row, Col, Button, Spin, Select} from 'antd';
import Products from './Products';
import Editor from './Editor';
import {ReactComponent as Empty} from '../../../assets/icons/empty.svg';
import styles from '../styles/ProductsUI.module.css';

const {Option} = Select;

const ProductsUI = props => {
  const {
    loading,
    products,
    selectProduct,
    newProduct,
    type,
    product,
    toppings,
    handleProduct,
    resetProduct,
    handleType,
    branches,
    branch,
    selectBranch,
    animations,
  } = props;

  const [fixedColHeight, setFixedColHeight] = useState('auto');
  const fixedColRef = useRef(null);
  const scrollableColRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (fixedColRef.current) {
        setFixedColHeight(`${fixedColRef.current.offsetHeight}px`);
      }
    };

    const observer = new ResizeObserver(updateHeight);
    if (fixedColRef.current) observer.observe(fixedColRef.current);

    updateHeight();

    return () => {
      observer.disconnect();
    };
  }, [product]);
  
  return (
    <div className={styles.view}>
      <Row>
        <Col xs={24}>
          <div className={styles.header}>
            <h1>Productos</h1>
            <div className={styles.actions}>
              {/* <Select
                className={styles.input}
                loading={loading}
                disabled={loading}
                showSearch
                size="large"
                placeholder="Seleccionar Tienda"
                optionFilterProp="children"
                value={branch}
                onChange={selectBranch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {branches.map(branch => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
                </Select> */}
              <Button onClick={newProduct} size="large">
                NUEVO PRODUCTO
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={40} className={styles.rowWrap}>

          <Col xs={6} ref={scrollableColRef} className={styles.scrollableCol} style={{ maxHeight: fixedColHeight }}>
            <Products
              products={products}
              selectProduct={selectProduct}
              selectedProduct={product}
            />
          </Col>

          <Col xs={18} ref={fixedColRef} className={styles.fixedCol}>
            {type === '' ? (
              <div className={styles.empty}>
                <Empty />
                <span>Selecciona el producto a editar</span>
              </div>
            ) : (
              <Editor
                product={product}
                categories={products}
                type={type}
                toppings={toppings}
                handleProduct={handleProduct}
                resetProduct={resetProduct}
                handleType={handleType}
                branch={branch}
                animations={animations}
              />
            )}
          </Col>
        </Row>

      )}
    </div>
  );
};

export default ProductsUI;
