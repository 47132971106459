import { Table, Spin, DatePicker,Button, Switch } from "antd";
import {Link} from 'react-router-dom';
import { getColumnSearchProps } from "../../../utils/tables";
import {ReactComponent as Arrow} from '../../../assets/icons/arrow-left.svg';
import {ReactComponent as Calendar} from '../../../assets/icons/calendar.svg';
import styles from "../styles/BoletinsUI.module.css";
import dayjs from "dayjs";
import "dayjs/locale/es"; 
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const {RangePicker} = DatePicker;

const BoletinUI = (props) => {
  const { loading, boletines, handleActive, beginDate, endDate, handleDates, exportData, page, handlePage } = props;

  const columns = [
    {
      title: "No",
      dataIndex: "correlativo",
      align: "center",
      hidden: true,
      render: (_, __, index) => (page - 1) * 10 + index + 1,
    },    
    {
      title: "Cuenta de correo",
      dataIndex: "email",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Fecha Registro",
      dataIndex: "date_created",
      render: (date) => dayjs(date).locale("es").format("DD/MM/YYYY hh:mm A"),
    }
    ,
    {
      title: "Activo",
      dataIndex: "active",
      align: "center",
      filters: [
        { value: 1, text: "Activo" },
        { value: 0, text: "Desactivado" },
      ],
      filterMultiple: false,
      onFilter: (val, record) => !!record.active === !!val,
      render: (val, record, idx) => (
        <Switch checked={val} onChange={(checked) => handleActive(record.id, idx, checked)} />
      ),
    }
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Link to="/ajustes" className={styles.back}>
            <div className={styles.backButton}>
              <Arrow />
            </div>
            <span>Lista de correos de boletín</span>
        </Link>
        <div>
          <RangePicker
            size="large"
            suffixIcon={<Calendar />}
            className={styles.picker}
            placeholder={['Fecha Inicio', 'Fecha Final']}
            allowClear={false}
            value={[beginDate, endDate]}
            onChange={handleDates}
          />
          <Button disabled={loading || (boletines.length == 0)} size="large" type="primary" onClick={() => { exportData({date_begin: beginDate.format('DD/MM/YYYY 00:00:00'), date_end: endDate.format('DD/MM/YYYY 23:59:59')}) } }>
            Exportar
          </Button>
        </div>
      </div>
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div className={styles.boletinTable}>
          <Table
            columns={columns}
            dataSource={boletines}
            rowKey="id"
            pagination={{
              defaultCurrent: page,
              onChange: handlePage,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BoletinUI;
