import {createSlice} from '@reduxjs/toolkit';
import {
  getBoletines,
  getFilteredBoletines,
  getExportBoletin,
  changeActive
} from '../../api/Boletines';

export const boletinesSlice = createSlice({
  name: 'boletines',
  initialState: {
    boletins: [],
    page: 1,
    loaded: false,
  },
  reducers: {
    setBoletines: (state, action) => {
      state.boletins = action.payload;
    },
    setBoletin: (state, action) => {
      const index = state.boletins.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.boletins[index] = action.payload;
      }
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setActive: (state, action) => {
      const {index, val} = action.payload;
      state.boletins[index].active = val;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setBoletines, setBoletin, setPage, setLoaded, setActive} = boletinesSlice.actions;

export const getAllBoletines = () => async dispatch => {
  try {
    const response = await getBoletines();
    if (!response.error && response.status === 200) {
      dispatch(setBoletines(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const getFilterBoletines = (fecha) => async dispatch => {
    try {
      const response = await getFilteredBoletines(fecha);
      if (!response.error && response.status === 200) {
        dispatch(setBoletines(response.data));
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    } catch (e) {
      return {
        status: 'error',
        type: 'unknown',
      };
    }
};

export const getExportBoletines = async (fecha) => {
    try {
      const response = await getExportBoletin(fecha);
      if (!response.error && response.status === 200) {
        return response;
      }
      return {
        status: 'error',
        type: 'unkown'
      };
    } catch (e) {
      return {
        status: 'error',
        type: 'unknown',
      };
    }
};

export const activeBoletin = (id, index, val) => async dispatch => {
  try {
    dispatch(setActive({index, val}));
    const response = await changeActive(id);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
      };
    }
    
    return {
      status: 'error',
      type: 'unkown'
    };
  } 
  catch (e) {
    dispatch(setActive({index, val}));
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectBoletines = state => state.boletines.boletins;
export const selectPage = state => state.boletines.page;
export const selectLoaded = state => state.boletines.loaded;

export default boletinesSlice.reducer;
