import instance from './Request';

export const getBoletines = async () => {
  const request = await instance();
  let data = await request
    .get('/newsletters/all')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getFilteredBoletines = async (fecha) => {
    const request = await instance();
    let data = await request
      .post('/newsletters/filtered', fecha)
      .catch((error) => {
        return {
          error,
        };
      });
    return data;
};

export const getExportBoletin = async (fecha) => {
    const request = await instance();
    let data = await request
      .post('/newsletters/export', fecha, { responseType: 'blob'})
      .catch((error) => {
        return {
          error,
        };
      });
    return data;
};

export const changeActive = async boletin => {
  const request = await instance();
  let data = await request
    .get(`/newsletters/activate/${boletin}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};